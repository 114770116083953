@import '~antd/dist/antd.css';

.wraps {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    padding-top: 2rem;
}
.inputs::-webkit-input-placeholder{
    color: #8C96B9;
}
.inputs:-moz-placeholder {
    color: #8C96B9;
}
.inputs:-ms-input-placeholder {
    color: #8C96B9;
}
input{
    border:0
}
p {
    margin: 0;
    font-size: 0.9rem
}
.black_font{
    font-weight: bold;
    font-size: 1.5rem;
    color: #515D8A;
}
.gray_font{
    color: #8C96B9;
}
.input_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
}
.modalInputWraps{
    flex: 1;
    height: 2rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #8C96B9;
    border-bottom-style: solid;
}
.modalColumn{
    width: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.inputWraps{
    /* width: 11rem; */
    height: 2rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-bottom-color: #8C96B9;
    border-bottom-style: solid;
}
.buttonRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2; 
}
.buttonStyle{
    width: 7.96rem;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:0.8rem; 
    color: #8C96B9;
    margin: 0;
    padding: 0
}
.codeWrap{
    flex-direction: row;
    margin-top: 2rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
}