.butWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    bottom: 3rem;
}
.butImg{
    margin: 0;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem
}

.butStyle{
    width: 10rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
}